import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './oneStopSolution.css';
import mockTemplate from "../../images/mockTemplate.jpg";
import headingBackground from "../../assets/onestopSolBg.webp"
const OneStopSolution = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 }); 
  }, []);

  return (
    <div className="container-main">
      {/* Heading */}
      <div className="heading-container" 
      
      style={{
      
        backgroundImage: `url(${headingBackground})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat'
        
        }}>
        <h3 className="heading-main" data-aos="fade-right" 
         
        >We are One <strong>Stop Solution Providers</strong></h3>
      </div>
      {/* Background photo */}
      <div className="photo-container" data-aos="fade-up"> 
        {/* Photo */}
        <img src={mockTemplate} alt="Background" className="photo" />
      </div>
    </div>
  );
};

export default OneStopSolution;
