import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './clients.css'; // Your custom CSS file
import LR from "./clientLogos/lcr.webp"
import jsconsultant from "./clientLogos/jsConsultants.webp"
import veuloCars from "./clientLogos/veuloCars.webp"
import SE from "./clientLogos/SaringEntp.webp"
import ZahrawiCollege from "./clientLogos/college-logo.webp"

const Clients = () => {
  const sliderRef = useRef(null);
  const clientLogos = [
    veuloCars,
    jsconsultant,
    ZahrawiCollege,
    LR,
    SE,
    ZahrawiCollege,
  ];

  const settings = {
    dots: false,
    infinite: true, 
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <></>, // Hide default previous button
    nextArrow: <></>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  return (
    <div className="clients">
      <div className="container">
        <div className="section-header">
          <h1>Our Clients</h1>
       
        </div>
        <div className="clients-carousel">
          <Slider {...settings} ref={sliderRef}>
            {clientLogos.map((logo, index) => (
              <div key={index} className="client-item">
                <img src={logo} alt={`Client Logo ${index + 1}`} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Clients;
