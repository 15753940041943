import React, { useState } from 'react';
import { ScrollArea } from "../components/ui/scroll-area";
import { Separator } from '../components/ui/separator';
import headingBackground from "../images/faq.jpg"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion";
import AskProject from './testimonialsPage/askProject/askProject';

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleAccordionToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const faqData = [
    {
      question: 'What is the estimated cost of my project?',
      answer:
        'For an accurate project cost, please schedule a brief call with us. We will discuss your project details and provide a tailored estimate based on your specific requirements',
    },
    {
      question: 'How long does the design process take?',
      answer:
        'The project timeline depends on your unique requirements. Share your timeframe during our initial discussions, and we will work on a realistic schedule to meet your needs.',
    },
    {
      question: 'How can I engage with DevVerse?',
      answer:
        'Start by sharing your project requirements with us. We will analyze your needs and provide a detailed proposal, guiding you through a seamless onboarding process.',
    },
    {
      question: 'Can you enhance the professionalism of my current site?',
      answer:
        'Certainly. Share your requirements, and our experienced team will collaborate with you to elevate the visual appeal and functionality of your existing site.',
    },
    {
      question: 'What is the payment schedule?',
      answer:
        'Payments are structured around project milestones. Typically, equal installments are made at the start, midway, and project completion, ensuring a fair and transparent payment process.',
    },
    {
      question: 'How do you handle project revisions',
      answer:
        'We include a set number of revision rounds in our proposals. Additional revisions may incur extra charges.',
    },
    {
      question: 'Do you provide ongoing support after the project is completed?',
      answer:
        'Yes, we offer maintenance and troubleshooting services post-project completion.',
    },
 
  ];

  return (
    <section className="faq px-4 sm:px-6 lg:px-8 min-h-screen flex flex-col" style={{ paddingLeft: 0, paddingRight: 0 }}>
    <h1 className="faq-heading text-5xl sm:text-7xl font-bold mb-10 text-center"
      style={{
        backgroundImage: `url(${headingBackground})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        padding: '100px', 
        color:"white"
      }}
    >
      Frequently Asked <strong>Questions</strong>
    </h1>
    <div className="faqmain flex flex-col md:flex-row max-w-screen-xl mx-auto" style={{ paddingLeft: '1rem', paddingRight: '1rem', xs: { paddingLeft: '1rem', paddingRight: '1rem' } }}> 
  <div className="faqLeft mb-8 md:w-1/2 md:pr-8">
    <h3 className='text-4xl font-semibold mb-10 md:mb-4 border-b-4 border-blue-500'>FAQ</h3>
    <h1 className="text-7xl font-semibold mb-16">Get answers to the most common questions.</h1>
  </div>
  <div className="faqRight w-full max-w-screen-xl mx-auto"> 
  <ScrollArea className="max-h-[400px] rounded-md border w-full overflow-y-auto">
    <Accordion type="single" collapsible>
      {faqData.map((faq, index) => (
        <AccordionItem key={index} value={`item-${index}`} className="w-full">
          <AccordionTrigger onClick={() => handleAccordionToggle(index)}>
            <span>{openIndex === index ? '-' : '+'}</span>
            <h3>{faq.question}</h3>
          </AccordionTrigger>
          <AccordionContent>
            <p className='text-3xl p-6'>{faq.answer}</p>
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  </ScrollArea>
</div>
</div>
<AskProject/>

  </section>
  );
};

export default FAQSection;
