import React, { useEffect, useState } from 'react';
import Carousel from '../../component/caorusel/carouselComp';
import WelcomeMessage from '../../component/welcomeMesg/welcomeMessage';
import Services from '../../component/services/services';
import WhyUS from '../../component/whyUs/whyUs';
import OneStopSolution from '../../component/OneStopSolution/oneStopSolution';
import LatestWorkComponent from '../../component/ProjectPortfolio/latestWork';
import PortfolioStats from '../../component/portfolioStats/portfolioStats';
import TechStackSlider from '../../component/techStack/techStack';
import Clients from '../../component/clients/clients';
import './home.css';

const Home = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isXSmallScreen, setIsXSmallScreen] = useState(false);

  useEffect(() => {
    document.title = 'DevVerse | Top Software House';

    // Check if screen size is small or extra small
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
      setIsXSmallScreen(window.innerWidth <= 597);
    };

    handleResize(); // Check on initial render
    window.addEventListener('resize', handleResize); // Listen for resize events

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up the listener
    };
  }, []);

  return (
    <div className='homePage-container'>
    <Carousel />
    <WelcomeMessage />
    <Services />
    <WhyUS />
    <OneStopSolution />
    {!isXSmallScreen && <LatestWorkComponent showAskProject={false} />}
    {!isXSmallScreen && <PortfolioStats />}
    <TechStackSlider />
    <Clients />
  </div>
  );
};

export default Home;
