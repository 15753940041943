import React from 'react';
import { motion } from 'framer-motion'; // Import motion from Framer Motion
import './TestimonialsBanner.css'; // Import the CSS file for styling
import Clients from '../../clients/clients';
import testimonialBackground from "../../../assets/home-testimonial-background.webp"

const TestimonialsBanner = () => {
  return (
    <section className="testimonials-banner" style={{
      
    backgroundImage: `linear-gradient(rgba(35, 86, 189, 0.6), rgba(35, 86, 189, 0.2)), url(${testimonialBackground})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat'
    
    }}>
    <div className="banner-content">
      {/* Use motion.div for animations */}
      <motion.h2 
        initial={{ opacity: 0, y: -20 }} 
        animate={{ opacity: 1, y: 0 }} 
        transition={{ delay: 0.9 }} 
        className="banner-heading" 
        style={{fontFamily:"Inter, sans-serif", fontWeight:"600", fontSize:"50px"}}
      >
        Testimonials
      </motion.h2>
      {/* Use motion.p for animations */}
      <motion.p
        initial={{ opacity: 0, y: -20 }} 
        animate={{ opacity: 1, y: 0 }} 
        transition={{ delay: 1.5 }} 
        className="banner-paragraph"
      >
        Our clients are so much satisfied by working with us. The 100% satisfaction ratio and tremendous respect showcase the affection of clients towards the company. Each client is like a star in the constellation, without it the beauty is incomplete.
      </motion.p>
    </div>
    <div className="clients-container">
      <Clients />
    </div>
    <div className="wave wave1"></div>
    <div className="wave wave2"></div>
    <div className="wave wave3"></div>
  </section>
  );
};

export default TestimonialsBanner;
