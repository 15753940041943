import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './userReviews.css';
import javedShahani from "./clientImages/javedSahani.webp"
import ls from "../../../assets/lcr png.webp";

import defaultPhoto from "../../../assets/defaultPhoto.webp";
import collegeLogo from "../../../assets/college-logo.webp";
import Veulo from "../../../assets/veuloCars.webp";
import SE from "../../../assets/se.webp";
const testimonials = [
  {
    id: 1,
    text: "The VeuloCar Rental website and marketing campaign they created for us have been a game-changer. Our online presence and brand visibility have increased, resulting in a significant increase in bookings. Their team truly understands our business goals and delivers outstanding results.",
    name: "Danish Ali",
    designation: "Founder & CEO, VUELO Car Rental",
    image: defaultPhoto,
  },
  {
    id: 2,
    text: "Working with their team on our consulting agency's website portfolio was an absolute pleasure. They captured the essence of our brand and showcased our expertise in a visually stunning and user-friendly manner. Their attention to detail and commitment to excellence are truly commendable.",
    name: "Javed Shahani",
    designation: "CEO JS Consulting",
    image: javedShahani,
  },
  {
    id: 3,
    text: "As an educational institution, we were looking for a website that would engage students, parents, and faculty alike. Their team not only delivered an exceptional online platform but also provided valuable insights on user experience and accessibility. We are thrilled with the result.",
    name: "Dr. Kashif Javed",
    designation: "Principal, Zahrawi Education System",
    image: collegeLogo,
  },
  {
    id: 4,
    text: "For our rent-a-car business, having a seamless online booking system was crucial. Their developers worked tirelessly to integrate our existing systems and create a user-friendly platform that streamlined the entire process. We are impressed by their technical expertise and dedication to solving complex challenges.",
    name: "Muhammad Arif ",
    designation: "CEO, Sarang Enterprises",
    image: defaultPhoto,
  },
  {
    id: 5,
    text: "When we needed a robust network marketing website, their team truly went above and beyond. From conceptualization to implementation, they worked closely with us, ensuring that every aspect of the site aligned with our vision. Their collaborative approach and problem-solving skills are truly remarkable.",
    name: "Cyril",
    designation: "CEO, D Casters",
    image: defaultPhoto,
  },
  {
    id: 6,
    text: "DevVerse delivered beyond our expectations with our business website. Their collaborative approach and problem-solving skills made the process seamless.",
    name: "Umair Ashraf",
    designation: "Business Development Officer Lapsoride PVT",
    image: defaultPhoto,
  },
];

const settings = {
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  centerMode: true, 
  centerPadding: '0px 100px 0px 0px',
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
      }
    }
  ]
};


const UserReviews = () => {

  return (
    <div className="user-reviews-container">
      <div className="content-container">
        <div className="content-header">
          <h2>What they've said about us</h2>
          <p>Employers 100% satisfaction ratio and tremendous respect showcase the affection of employers towards DevVerse.</p>
          <Link to="/contact">
            <button>Contact Us <span style={{ paddingLeft: '5px', fontSize: "20px" }}>&rarr;</span></button>
          </Link>
        </div>
      </div>

      <div className="testimonial-container">
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="slide">
            <div className="testimonial-card">
              <div className="backQuote">
                <p>{`"${testimonial.text}"`}</p>
              </div>
              <div className="testimonial-profile">
                <div className="testimonial-img">
                  <img src={testimonial.image} alt="Testimonial image" />
                </div>
                <div className="testimonial-details">
                  <h3>{testimonial.name}</h3>
                  <h4>{testimonial.designation}</h4>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      </div>
    </div>
  );
};

export default UserReviews;
