import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '@fortawesome/fontawesome-svg-core/styles.css'
import './App.css';
import Contact from './component/Contact/contact';
import Faq from './component/faq';
import Footer from './component/Footer/foot';
import LatestWorkComponent from './component/ProjectPortfolio/latestWork';
import Services from './component/services/services';
import Header from './component/Header/header';
import TestimonialsPage from './component/testimonialsPage/testimonials/testimonials';
import ReqQuotePage from './component/getQuotePage/reqQuote/reqQuote';
import WebDevelopmentPage from './Pages/Services/WebDevelopment/webDevlopment';
import MobileAppDevelopment from './Pages/Services/MobileAppDevelopment/mobileAppDevelopment';
import GraphicDesign from './Pages/Services/GraphicDesigning/graphicDesigning';
import SeoServices from './Pages/Services/SeoServices/seoServices';
import DigitalMarketing from './Pages/Services/DigitalMarketing/digitalMarketing';
import BARS from './Pages/ProjectsPages/Project1/project1';
import SmartCode from './Pages/ProjectsPages/Project2/project2';
import UPMobile from './Pages/ProjectsPages/Project3/project3';
import OCEAM from './Pages/ProjectsPages/Project4/project4';
import SwipeGallery from './Pages/ProjectsPages/Project6/project6';
import TheDrawers from './Pages/ProjectsPages/Project7/project7';
import ScrollToTopOnRouteChange from './component/scrollToTopOnPageChange/scrollTop';
import GetQuotePage from './component/getQuotePage/getQuotePage';
import GoToTop from './component/gotoTop/goToTop';
import ChatIcon from './component/chatIcon/chatIcon';
import Home from './Pages/Home/home';
import PageNotFound from './Pages/PageNotFound/pageNotFound';
import BlogPage from './Pages/Blogs/blogPage';
import BlogDetails from './Pages/blogsDetails/blogDetails';
import blogData from "../src/Pages/Blogs/cardsData"
function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTopOnRouteChange/>
        <ChatIcon/>
        <Header />  
        <GoToTop/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/request-quote" element={<GetQuotePage />} />
          <Route path="/portfolio" element={<LatestWorkComponent />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/blogs" element={<BlogPage />} />
          <Route path="/testimonials" element={<TestimonialsPage />} />
          <Route path="/web-development" element={<WebDevelopmentPage />} />
          <Route path="/mobile-apps" element={<MobileAppDevelopment />} />
          <Route path="/graphic-designing" element={<GraphicDesign />} />
          <Route path="/seo-services" element={<SeoServices />} />
          <Route path="/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/project/115/bars-application" element={<BARS />} />
          <Route path="/project/116/smart-code-application" element={<SmartCode />} />
          <Route path="/project/117/up-mobile-application" element={<UPMobile />} />
          <Route path="/project/118/oceam-application" element={<OCEAM />} />
          <Route path="/project/119/swipe-gallery" element={<SwipeGallery />} />
          <Route path="/project/121/the-drawers" element={<TheDrawers />} />

          <Route path="/blog/:title" element={<BlogDetails data={blogData} />} />
          
          <Route path="*" element={<PageNotFound />} />


        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
