// Breadcrumb.js
import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import bgImage from '../../assets/breadbg.jpg';

const Breadcrumb = ({ breadcrumbItems = [] }) => {
  return (
    <div className="breadcrumb-container" style={{ backgroundImage: `url(${bgImage})` }}>
      <div className="breadcrumb-heading">
        <Typography variant="h1" className="breadcrumb-page">
          {breadcrumbItems[breadcrumbItems.length - 1] || ''}
        </Typography>
      </div>
      <div className="breadcrumb-text">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/" className="breadcrumb-link" style={{ fontSize: "18px" }}>
            Home
          </Link>
          {breadcrumbItems.map((item, index) => (
            <React.Fragment key={index}>
              {index < breadcrumbItems.length - 1 ? (
                <Link
                  color="inherit"
                  href={`/${breadcrumbItems.slice(0, index + 1).join('/')}`}
                  className="breadcrumb-link"
                  style={{ fontSize: "16px" }}
                >
                  {item}
                </Link>
              ) : (
                <Typography color="textPrimary" className="breadcrumb-page" style={{ fontSize: "16px" }}>
                  {item}
                </Typography>
              )}
            </React.Fragment>
          ))}
        </Breadcrumbs>
      </div>
    </div>
  );
};

export default Breadcrumb;