

import React from 'react';
import blogData from './cardsData'; 
import BlogCard from '../../component/blogCard/blogcard';
import headingBackground from "../../assets/blogs-heading-bg.webp"

const BlogPage = () => {
  return (
    <div>
   
     <div className="blogs-heading-container"
            style={{
                backgroundImage: `linear-gradient(rgba(35, 86, 189, 0.6), rgba(35, 86, 189, 0.6)), url(${headingBackground})`, // Added a closing parenthesis after linear-gradient
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundAttachment: 'fixed',
                backgroundRepeat: 'no-repeat',
                padding: '8%',
                color:"white"
              }}
            >

            <h1 className="blogs-heading text-6xl sm:text-7xl font-bold mb-4 text-left">
                DevVerse <strong>Blogs</strong>
            </h1>
            <h2 className="blogs-heading text-3xl sm:text-5xl text-left">
                Business Updates & Spontaneous Reflections.
            </h2>
        </div>
      
      <BlogCard data={blogData} />
    </div>
  );
}

export default BlogPage;
