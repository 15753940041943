import React from 'react';
import { Link } from 'react-router-dom';

import './BlogCard.css';

const BlogCard = ({ data }) => {

  const stripHtmlTags = (htmlString) => {
    return htmlString.replace(/<[^>]*>?/gm, '');
  };
  
  const formatUrl = (title) => {
    return title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
  };
  return (
    <div className="blogCard-container">
      <div className="card-grid">
        {data.map((item) => (
          <Link to={`/blog/${formatUrl(item.title)}`} key={item.id} className="card"> {/* Update the to attribute */}
            <div className="card-image">
              <img src={item.image} alt="Blog Image" />
            </div>
            <div className="card-content">
              <div className="date-posted">{item.datePosted}</div>
              <div className="tags">
                {item.tags.map((tag, index) => (
                  <span key={index} className="tag">
                    {tag}
                  </span>
                ))}
              </div>
              <div className="card-title">{item.title}</div>
             <p className="card-text">{stripHtmlTags(item.content).slice(15, 200)}...</p> 
              <span className="read-more">Read more <i className="fas fa-arrow-right"></i></span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogCard;
