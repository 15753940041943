import React from 'react';
import { useParams } from 'react-router-dom';
import './blogDetails.css'

function BlogDetails({ data }) {
  const { title } = useParams();


  const formatUrl = (title) => {
    return title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
  };

  
  const blog = data.find(blog => formatUrl(blog.title) === title);

  if (!blog) {
    return <div>Blog not found</div>;
  }
  
  return (
    <div className="blog-details-container">
      <div className="blog-header">
        <div
          className="blog-header-image"
          style={{
            backgroundImage: `url(${blog.image})`,
      
          }}
        />
        <div className="blog-header-content">
          <div className="tags">
            {blog.tags.map((tag, index) => (
              <span key={index} className="tag">
                {tag}
              </span>
            ))}
          </div>
          <h1 className="blog-title">{blog.title}</h1>
          <div className="author-info">
          </div>
        </div>
      </div>
      
              <div className="date-posted">{blog.datePosted}</div>
          

      <div className="blog-content">
       
        <div dangerouslySetInnerHTML={{ __html: blog.content }} />
        {/* Add sections for recommended blogs, comments, etc. */}
      </div>

    </div>
  );
};

export default BlogDetails;
