import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { IconButton } from '@mui/material';
import '../projects.css';
import img7 from "../../../images/Slider_7.webp";
import RecentProjects from '../../../component/RecentProjects/recentProjects';
import Breadcrumb from '../../../component/Breadcrumbs/breadcrumb';

const TheDrawers = () => {
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isAutoplayEnabled, setIsAutoplayEnabled] = useState(false);
    useEffect(()=>{

      document.title='DevVerse | The Drawers'
    })
  const project = {
    name: "",
    description: "The Drawers is a powerful web-based document management and collaboration system designed for organizations. Developed using Python with Django framework and MySQL database, it offers secure storage, version control, and access control features. Its user-friendly interface and robust backend architecture make it ideal for businesses seeking efficient document management solutions.",
    TechStack: "Python, Django, MySQL",
    images: [img7]
  };

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 2000,
    pauseOnHover: true,
  };


  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsImageModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsImageModalOpen(false);
    setSelectedImage(null);
  };

  const handleModalClick = (e) => {
    // Prevent the click event from bubbling up to the parent container
    e.stopPropagation();
  };

  const handleToggleAutoplay = () => {
    setIsAutoplayEnabled(!isAutoplayEnabled);
  };

  return (
    <div className="project-page-container">
      {/* Breadcrumb */}
     
     <Breadcrumb breadcrumbItems={[ 'Portfolio', 'The Drawers']} />

      <div className="project-detail-container">
        {/* Carousel */}
        <div className="project-carousel-container">
        <Slider {...settings}>

            {project.images.map((image, index) => (
              <div key={index} className="project-carousel-item" onClick={() => handleImageClick(image)}>
                <img src={image} alt={`Project ${index + 1}`} />
                {/* Thumb Overlay */}
                <div className="thumb-overlay">
                  <FullscreenIcon className="full-screen-icon" style={{fontSize:"45px"}}/>
                </div>
              </div>
            ))}
        </Slider>
        </div>

        {/* Description */}
  
        <div className="description-container">
          <Typography variant="h2" className='Project-description-heading1'><h4
          style={{
            fontSize:"32px",
            lineHeight: "22px",
            marginBottom:"8px",
            fontWeight:"600"
          }}
          >Project Description</h4></Typography>
          <Typography variant="body1" className='Project-description-text' 
          style={{
            marginTop:"8px",
            fontSize:"16px",
            lineHeight:"21px"
          }}
          >{project.description}</Typography>
          <Typography variant="h2" className='Project-description-heading1'><h4
          style={{
            fontSize:"32px",
            fontFamily:"Inter, san-serif",
            lineHeight: "22px",
            marginBottom:"8px",
            fontWeight:"600",
            marginTop:"12px"
          }}
          >Tech Stack</h4></Typography>
          <Typography variant="body1" className='Project-description-text' 
          style={{
            marginTop:"8px",
            fontSize:"16px",
            lineHeight:"21px"
          }}
          >{project.TechStack.split(', ').map((tech, index) => (
            <li key={index}>{tech}</li>
          ))}</Typography>
        </div>

        {/* Full Screen Image Modal */}
        {isImageModalOpen && (
          <div className="full-screen-modal">
            <div className="full-screen-carousel-header">
              <Typography variant="h2">The Drawers</Typography>
            </div>
            <div className="full-screen-carousel-container">
            <Slider {...settings} autoplay={isAutoplayEnabled}>
                  {project.images.map((image, index) => (
                  <div key={index} className="full-screen-carousel-item">
                    <img className ="full-screen-carousel-img" src={image} alt={`Project ${index + 1}`} />
                  </div>
                ))}
          </Slider>
            </div>
            <CloseIcon className="close-icon" onClick={handleCloseModal} />
            <IconButton onClick={handleToggleAutoplay} className="autoplay-button">
              <PlayCircleOutlineIcon color={isAutoplayEnabled ? "primary" : "action"} />
            </IconButton>
          </div>
        )}
      </div>
      <RecentProjects/>
    </div>
  );
};

export default TheDrawers;
